import styled from "styled-components";

export const CommunityHeroDiv = styled.div`
  --colorH3: ${(props) => props.colorH3 || "var(--white)"};
  --colorH4: ${(props) => props.colorH4 || "var(--green)"};
  --colorH5: ${(props) => props.colorH5 || "var(--green)"};
  --colorH6: ${(props) => props.colorH6 || "var(--white)"};
  --textTransform: ${(props) => props.textTransfrom || "none"};
  --fontSizeH5: ${(props) => props.fontSizeH5 || "42px"};
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 35px var(--outerMargin);
  & h6 {
    font-size: 21px;
    letter-spacing: 0.06em;
    text-transform: var(--textTransform);
  }
  & h4,
  h5 {
    font-weight: 600;
    width: 1240px;
    line-height: 125%;
    font-style: normal;
    & > p {
      margin: 0;
    }
  }
  & h4 {
    color: var(--colorH4);
    line-height: 52.5px;
  }
  & h5 {
    color: var(--colorH5);
    margin-bottom: 25px;
    text-align: center;
    font-family: var(--headerFont);
    text-transform: none;
    font-size: var(--fontSizeH5);
    letter-spacing: 0.01em;
  }

  & h3 {
    color: var(--colorH3);
    text-align: center;
    max-width: calc(
      calc(var(--width) - var(--outerMargin)) - var(--outerMargin)
    );
    margin-left: auto;
    margin-right: auto;
    font-size: 54px;
  }
  /* background-color: var(--black); */
  @media (max-width: 1240px) {
    & h5 {
      width: 100%;
    }
  }

  @media (max-width: 767px) {
    --outerMargin: 25px;
    /* padding: 25px 20px; */
    padding: 0;
    & h5 {
      /* text-align: left; */
      font-size: 24px !important;
      line-height: 125%;
      text-align: center !important;
      width: 100% !important;
    }
    & h3 {
      /* text-align: left; */
      font-size: 24px;
      letter-spacing: 0.24px;
      font-size: 32px !important;
      line-height: 110%;
      margin-bottom: 20px;
    }
  }
`;
