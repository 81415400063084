import React from "react";
import PropTypes from "prop-types";
import CoreFilter from "./../CoreFilter";

const communityTypes = [
  { id: "Food", name: "Food", color: "var(--green)" } /* (same) */,
  {
    id: "Art & Design",
    name: "Art & Design",
    color: "var(--green)",
  } /* —> Art*/,
  {
    id: "Fitness & Wellness",
    name: "Fitness & Wellness",
    color: "var(--green)",
  } /* --> Lifestyle & Wellness */,
  {
    id: "Artists",
    name: "Artists",
    color: "var(--green)",
  } /* --> Makers */,
  {
    id: "Kids",
    name: "Kids",
    color: "var(--green)",
  } /*  --> Culture & Education  */,
  {
    id: "Theatre & Film",
    name: "Theatre & Film",
    color: "var(--green)",
  } /* --> Production & Consultancy */,
  {
    id: "Performing Arts",
    name: "Performing Arts",
    color: "var(--green)",
  } /*--> disappears? */,
];

export const getCommunityTypeFromID = (id) => {
  const list = communityTypes.filter((x) => x.id === id);
  if (list.length) {
    return list[0].name;
  } else {
    console.error("Problem: ", id);
  }
};

const CommunityFilter = ({ selected, selectLocation }) => {
  switch (selectLocation) {
    case "food":
      selectLocation = "Food";
      break;
    case "art-and-design":
      selectLocation = "Art & Design";
      break;
    case "fitness-and-wellness":
      selectLocation = "Fitness & Wellness";
      break;
    case "artists":
      selectLocation = "Artists";
      break;
    case "kids":
      selectLocation = "Kids";
      break;
    case "theatre-and-film":
      selectLocation = "Theatre & Film";
      break;
    case "performing-arts":
      selectLocation = "Performing Arts";
      break;
    default:
      selectLocation = "";
  }

  return (
    <CoreFilter
      selected={selected}
      filterList={communityTypes}
      useColor
      modal
      selectLocation={selectLocation}
    />
  );
};

export default CommunityFilter;

CommunityFilter.propTypes = {
  selected: PropTypes.func,
};
