import React, { useEffect, useRef, useState } from "react";
import Layout from "../components/Layout";
import CommunityHero from "../components/CommunityHero";
import CommunityFilter from "../components/CommunityFilter";
import CommunityCardHolder from "../components/CommunityCardHolder";
import { useStaticQuery, graphql } from "gatsby";
import { ParticipantsDiv } from "../styles/participants";
import { BigButtonParticipant } from "../components/ButtonsParticipants";
import { Location } from "@reach/router";

const ParticipantsPage = () => {
  const communityQuery = useStaticQuery(graphql`
    query {
      allCraftParticipantsDefaultEntry(filter: { isDraft: { eq: false } }) {
        nodes {
          id
          communityMemberSlug
          participantType
          communityMemberWebsite
          communityMemberEmailAddress
          subtitle
          communityMemberOpeningHours
          participantLocation {
            id
            title
          }
          communityMemberDescription
          communityMemberContactDetails {
            method
            value
          }
          slug
          title
          headerImage: qafParticipantHeaderImage {
            ... on Craft_communityAssets_Asset {
              localFile {
                publicURL
                childImageSharp {
                  fixed(width: 295, height: 295, fit: COVER) {
                    src
                    ...GatsbyImageSharpFixed
                  }
                }
              }
            }
          }
        }
      }
      craftPagesQafDefaultEntry(slug: { eq: "participants" }) {
        title
        pageQAFDescriptionOne
      }
      allCraftQafEventsQafEventEntry {
        nodes {
          id
          title
          isStaging
          eventEndDateTime
          eventStartDateTime
          eventShortDescription
          qafEventSubTheme
          qafEventLocation {
            title
            ... on Craft_participants_default_Entry {
              id
              participantLocation {
                id
                title
              }
              communityMemberOpeningHours
              communityMemberSlug
              communityMemberWebsite
              communityMemberEmailAddress
              communityMemberDescription
              communityMemberContactDetails {
                col1
                col2
              }
              participantType
            }
            ... on Craft_maplocation_default_Entry {
              locationId
              locationName
              title
              allowClick
            }
          }
          slug
          eventType
          headerImage: qafEventHeaderImage {
            ... on Craft_eventsAssets_Asset {
              cardImage: localFile {
                publicURL
                childImageSharp {
                  fixed(fit: COVER, width: 172) {
                    src
                    ...GatsbyImageSharpFixed
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  const [end, setEnd] = useState(9);
  const [currentTypes, setCurrentTypes] = useState([]);
  const [changedTypes, setChangedTypes] = useState(false);
  const [randomArray, setRandomArray] = useState([]);
  const [cardsFilter, setCardFilter] = useState([]);
  const [endFilter, setEndFilter] = useState(9);
  const cardsFilterTemp = useRef([]);

  const communityMembers =
    communityQuery?.allCraftParticipantsDefaultEntry?.nodes;

  const titlePartipantsPage = communityQuery?.craftPagesQafDefaultEntry;

  const now = new Date();
  const relatedEventsList =
    communityQuery?.allCraftQafEventsQafEventEntry?.nodes?.filter((item) => {
      const thisDate = new Date(
        item.eventEndDateTime || item.eventStartDateTime || null
      );
      return thisDate >= now;
    });

  useEffect(() => {
    for (let i = 0; i < communityMembers?.length; i++) {
      const relatedE = relatedEventsList.filter((e) => {
        const checkedArray = e?.qafEventLocation?.length
          ? e?.qafEventLocation.filter(
              (item) => item?.participantType && item?.participantType?.length
            )
          : [];

        return checkedArray.find(
          (item) => item?.id === communityMembers[i]?.id
        );
      });
      communityMembers[i].relatedEvents = relatedE;
    }
  }, []);

  useEffect(() => {
    cardsFilterTemp.current = communityMembers.filter((x) =>
      currentTypes.length === 0 ? true : checkTypes(x.participantType)
    );
    // console.log(currentTypes,"current cards:", currentCards);
    // console.log("cards after filter",cardsFilterTemp, "end", endFilter);
    const cardsTemp = cardsFilterTemp.current;
    setCardFilter(cardsTemp.slice(0, endFilter));
  }, [currentTypes, endFilter]);

  useEffect(() => {
    const temp = [];
    for (let i = 0; i < communityMembers?.length; i++) {
      let rand = Math.floor(Math.random() * (3 - 1 + 1) + 1);
      if (
        communityMembers[i].participantType?.length &&
        communityMembers[i].participantType[0] === "Food"
      ) {
        rand = -1;
      }
      temp.push(rand);
    }
    setRandomArray(temp);
  }, []);

  const currentCards = communityMembers.slice(0, end);

  const checkTypes = (typesArray) => {
    for (let i = 0; i < typesArray.length; i++) {
      if (currentTypes.indexOf(typesArray[i]) > -1) {
        return true;
      }
    }
    // console.log(typesArray);
    return false;
  };

  // console.log(communityMembers);

  const handleClickLoadMore = () => {
    setEnd((pre) => pre + 9);
  };

  const handleClickLoadMoreFilter = () => {
    setEndFilter((pre) => pre + 9);
  };

  return (
    <Location>
      {({ location }) => {
        return (
          <Layout
            backgroundColor={"var(--black)"}
            skipHeader
            headerBlack={true}
            //   footerColor={"var(--red)"}
            //   title="Alserkal Avenue | A Community for
            // Contemporary Art & Homegrown Concepts"
            //   SEODescription="If you want dynamic cultural experiences, from
            // contemporary art and photography to spinning and yoga
            // classes, discover Alserkal Avenue today."
          >
            <ParticipantsDiv>
              <CommunityHero
                topLine={
                  titlePartipantsPage && titlePartipantsPage.title
                    ? titlePartipantsPage.title
                    : "Participants"
                }
                text={
                  titlePartipantsPage &&
                  titlePartipantsPage.pageQAFDescriptionOne
                    ? titlePartipantsPage.pageQAFDescriptionOne
                    : "<p>Alserkal Avenue is a vibrant community of visual and performing</p>\n<p> arts organisations, designers, and artisanal spaces that have become an essential platform for the development of the creative industries </p>\n<p>in the United Arab Emirates.</p> "
                }
                colorH3={"var(--white)"}
                colorH5={"var(--green)"}
                fontSizeH5="32px"
              />

              <CommunityFilter
                selected={(e) => {
                  setCurrentTypes(e);
                  setChangedTypes(() => !changedTypes);
                }}
                selectLocation={
                  location.search.indexOf("?type=") > -1
                    ? decodeURI(location.search.split("?type=")[1])
                    : ""
                }
              />

              <CommunityCardHolder
                cards={
                  currentTypes?.length > 0
                    ? cardsFilter
                    : currentCards.filter((x) =>
                        currentTypes.length === 0
                          ? true
                          : checkTypes(x.participantType)
                      )
                }
                closeOverlay={changedTypes}
                randomArray={randomArray}
                cardsOrigin={communityMembers}
              />

              {currentTypes?.length > 0
                ? cardsFilterTemp.current.length > endFilter &&
                  cardsFilterTemp.current.length !== endFilter && (
                    <BigButtonParticipant
                      backgroundColor="var(--green)"
                      color="var(--black) "
                      onClick={handleClickLoadMoreFilter}
                    >
                      Load more
                    </BigButtonParticipant>
                  )
                : communityMembers.length > end &&
                  communityMembers.length !== end && (
                    <BigButtonParticipant
                      backgroundColor="var(--green)"
                      color="var(--black) "
                      onClick={handleClickLoadMore}
                    >
                      Load more
                    </BigButtonParticipant>
                  )}
            </ParticipantsDiv>
          </Layout>
        );
      }}
    </Location>
  );
};

export default ParticipantsPage;
